import { getEmailFromToken } from '@conteg/auth';
import { getGraphQLRequestName } from '@conteg/logging';
import { GqlError } from '@conteg/ui';
import { isInCypress } from 'components/is-in-cypress/is-in-cypress';
import { uniq } from 'lodash';
import { getToken } from 'utils/get-token/get-token';
import { logger } from 'utils/logging/init';

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const token = getToken();

    const res = await fetch(window.contegConfig.VITE_GQL_API_URL, {
      method: 'POST',
      ...{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.origin,
          'Access-Control-Request-Headers': 'X-Requested-With',
          'Access-Control-Request-Method': 'POST',
          authorization: `Bearer ${token}`,
        },
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const messages = json.errors.map((error: any) => {
        if ('message' in error) {
          return error.message;
        }
        return `Unexpected error ${JSON.stringify(error)}`;
      });

      //Use uniq to remove duplicate error messages
      const formattedErrors = uniq(messages).join('\n');

      if (!isInCypress()) {
        logger.error(
          JSON.stringify({
            email: getEmailFromToken(token),
            queryName: getGraphQLRequestName(query),
            variables,
            errors: json.errors,
          })
        );
      }

      throw new GqlError(
        variables,
        getGraphQLRequestName(query),
        json.errors,
        formattedErrors,
        res.status
      );
    }

    return json.data;
  };
}
