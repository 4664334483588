import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  UUID: string;
};

export type AllocationInspected = {
  __typename?: 'AllocationInspected';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  timeStampUtc: Scalars['DateTime'];
};

export type AllocationTerminated = {
  __typename?: 'AllocationTerminated';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  storageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ClientPhoneNumber = {
  __typename?: 'ClientPhoneNumber';
  clientType: ClientType;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum ClientType {
  Depositor = 'DEPOSITOR',
  Recipient = 'RECIPIENT',
  Sender = 'SENDER'
}

export type ContentDeallocated = {
  __typename?: 'ContentDeallocated';
  contentEventType: ContentEventTypeEnum;
  storageUnitId: Scalars['UUID'];
  timeStampUtc: Scalars['DateTime'];
};

export enum ContentEventTypeEnum {
  Deallocated = 'DEALLOCATED',
  Inspected = 'INSPECTED',
  PickedUp = 'PICKED_UP',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  SubmissionCodeValidated = 'SUBMISSION_CODE_VALIDATED',
  Terminated = 'TERMINATED',
  UnknownContentFound = 'UNKNOWN_CONTENT_FOUND',
  VerifyAccessCredentialsSuccess = 'VERIFY_ACCESS_CREDENTIALS_SUCCESS'
}

export type ContentPickedUp = {
  __typename?: 'ContentPickedUp';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  storageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export type ContentStockedIn = {
  __typename?: 'ContentStockedIn';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']>;
  storageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export type ContentStockedOut = {
  __typename?: 'ContentStockedOut';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  storageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export type ContentStored = {
  __typename?: 'ContentStored';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  phoneNumber?: Maybe<Scalars['String']>;
  pinCode?: Maybe<Scalars['String']>;
  storageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export type ContentTracking = AllocationInspected | AllocationTerminated | ContentDeallocated | ContentPickedUp | ContentStockedIn | ContentStockedOut | ContentStored | SubmissionCodeValidated | UnknownContentFound | VerifyPickUpAccessCredentialsSuccess;

export type ExportSmsDto = {
  __typename?: 'ExportSmsDto';
  messagesCount: Scalars['Int'];
  pointId?: Maybe<Scalars['UUID']>;
  pointRegistrationNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['UUID']>;
  projectName?: Maybe<Scalars['String']>;
  smsMessagesCount: Scalars['Int'];
  tenantSubjectId?: Maybe<Scalars['UUID']>;
  tenantSubjectName?: Maybe<Scalars['String']>;
};

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED'
}

export type PointMessageDto = {
  __typename?: 'PointMessageDto';
  deactivationReason?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageUrl?: Maybe<Scalars['String']>;
  installationId: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isConfigured: Scalars['Boolean'];
  isManualLayoutManagement: Scalars['Boolean'];
  isOpenForEdit: Scalars['Boolean'];
  pointIdentifier?: Maybe<Scalars['String']>;
  pointPrefix?: Maybe<Scalars['String']>;
  pointRegistrationNumber?: Maybe<Scalars['String']>;
  pointSequenceNumber: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  contentTracking?: Maybe<Array<Maybe<ContentTracking>>>;
  points?: Maybe<Array<Maybe<PointMessageDto>>>;
  sentSmsLogs: Array<ExportSmsDto>;
  storageUnitTracking?: Maybe<StorageUnitStatusChange>;
};


export type QueryContentTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  pageIndex?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']>;
  to?: InputMaybe<Scalars['DateTime']>;
  trackingIdentifier?: InputMaybe<Scalars['String']>;
};


export type QuerySentSmsLogsArgs = {
  pointId?: InputMaybe<Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  smsSentAtFrom?: InputMaybe<Scalars['String']>;
  smsSentAtTo?: InputMaybe<Scalars['String']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']>;
};


export type QueryStorageUnitTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  pointId?: InputMaybe<Scalars['UUID']>;
  storageUnitName?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type StorageUnitStatusChange = {
  __typename?: 'StorageUnitStatusChange';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  items: Array<StorageUnitTrackingMessageDto>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type StorageUnitTrackingMessageDto = {
  __typename?: 'StorageUnitTrackingMessageDto';
  installationId: Scalars['UUID'];
  lockStatus: LockStatus;
  pointId: Scalars['UUID'];
  pointRegistrationNumber: Scalars['String'];
  storageUnitId?: Maybe<Scalars['UUID']>;
  storageUnitName: Scalars['String'];
  timeStampUtc: Scalars['DateTime'];
};

export type SubmissionCodeValidated = {
  __typename?: 'SubmissionCodeValidated';
  contentEventType: ContentEventTypeEnum;
  isScanned: Scalars['Boolean'];
  isSuccessful: Scalars['Boolean'];
  timeStampUtc: Scalars['DateTime'];
};

export type UnknownContentFound = {
  __typename?: 'UnknownContentFound';
  actualStorageUnitId: Scalars['UUID'];
  contentEventType: ContentEventTypeEnum;
  expectedStorageUnitId?: Maybe<Scalars['UUID']>;
  timeStampUtc: Scalars['DateTime'];
};

export type VerifyPickUpAccessCredentialsSuccess = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pinCode?: Maybe<Scalars['String']>;
  timeStampUtc: Scalars['DateTime'];
};

export type AllocationInspectedFragment = { __typename?: 'AllocationInspected', firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum, timeStampUtc: string, email?: string | null };

export type AllocationTerminatedFragment = { __typename?: 'AllocationTerminated', firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum, timeStampUtc: string, email?: string | null };

export type ContentDeallocatedFragment = { __typename?: 'ContentDeallocated', timeStampUtc: string, contentEventType: ContentEventTypeEnum };

export type ContentPickedUpFragment = { __typename?: 'ContentPickedUp', timeStampUtc: string, email?: string | null, firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum };

export type ContentStockedInFragment = { __typename?: 'ContentStockedIn', courierIdentifier?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum };

export type ContentStockedOutFragment = { __typename?: 'ContentStockedOut', courierIdentifier?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum, email?: string | null, firstName?: string | null, lastname?: string | null };

export type ContentStoredFragment = { __typename?: 'ContentStored', pinCode?: string | null, phoneNumber?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum };

export type ExportSmsFragment = { __typename?: 'ExportSmsDto', pointRegistrationNumber?: string | null, tenantSubjectName?: string | null, projectName?: string | null, smsMessagesCount: number, messagesCount: number };

export type SubmissionCodeValidatedFragment = { __typename?: 'SubmissionCodeValidated', isSuccessful: boolean, isScanned: boolean, contentEventType: ContentEventTypeEnum, timeStampUtc: string };

export type UnknownContentFoundFragment = { __typename?: 'UnknownContentFound', timeStampUtc: string, contentEventType: ContentEventTypeEnum };

export type VerifyPickUpAccessCredentialsSuccessFragment = { __typename?: 'VerifyPickUpAccessCredentialsSuccess', pinCode?: string | null, phoneNumber?: string | null, timeStampUtc: string, email?: string | null, firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum };

export type ContentTrackingQueryVariables = Exact<{
  trackingIdentifier?: InputMaybe<Scalars['String']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageIndex?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;


export type ContentTrackingQuery = { __typename?: 'Query', contentTracking?: Array<{ __typename: 'AllocationInspected', firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum, timeStampUtc: string, email?: string | null } | { __typename: 'AllocationTerminated', firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum, timeStampUtc: string, email?: string | null } | { __typename: 'ContentDeallocated', timeStampUtc: string, contentEventType: ContentEventTypeEnum } | { __typename: 'ContentPickedUp', timeStampUtc: string, email?: string | null, firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum } | { __typename: 'ContentStockedIn', courierIdentifier?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum } | { __typename: 'ContentStockedOut', courierIdentifier?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum, email?: string | null, firstName?: string | null, lastname?: string | null } | { __typename: 'ContentStored', pinCode?: string | null, phoneNumber?: string | null, timeStampUtc: string, contentEventType: ContentEventTypeEnum } | { __typename: 'SubmissionCodeValidated', isSuccessful: boolean, isScanned: boolean, contentEventType: ContentEventTypeEnum, timeStampUtc: string } | { __typename: 'UnknownContentFound', timeStampUtc: string, contentEventType: ContentEventTypeEnum } | { __typename: 'VerifyPickUpAccessCredentialsSuccess', pinCode?: string | null, phoneNumber?: string | null, timeStampUtc: string, email?: string | null, firstName?: string | null, lastname?: string | null, contentEventType: ContentEventTypeEnum } | null> | null };

export type PointsQueryVariables = Exact<{ [key: string]: never; }>;


export type PointsQuery = { __typename?: 'Query', points?: Array<{ __typename?: 'PointMessageDto', id: string, pointSequenceNumber: number, pointRegistrationNumber?: string | null } | null> | null };

export type SentSmsLogsQueryVariables = Exact<{
  smsSentAtFrom?: InputMaybe<Scalars['String']>;
  smsSentAtTo?: InputMaybe<Scalars['String']>;
}>;


export type SentSmsLogsQuery = { __typename?: 'Query', sentSmsLogs: Array<{ __typename?: 'ExportSmsDto', pointRegistrationNumber?: string | null, tenantSubjectName?: string | null, projectName?: string | null, smsMessagesCount: number, messagesCount: number }> };

export type StorageUnitTrackingMessageFragment = { __typename?: 'StorageUnitTrackingMessageDto', storageUnitName: string, pointRegistrationNumber: string, pointId: string, installationId: string, lockStatus: LockStatus, timeStampUtc: string };

export type StorageUnitTrackingQueryVariables = Exact<{
  pointId?: InputMaybe<Scalars['UUID']>;
  storageUnitName?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
  pageSize: Scalars['Int'];
  pageIndex: Scalars['Int'];
}>;


export type StorageUnitTrackingQuery = { __typename?: 'Query', storageUnitTracking?: { __typename?: 'StorageUnitStatusChange', pageIndex: number, pageSize: number, totalPages: number, totalCount: number, hasPreviousPage: boolean, hasNextPage: boolean, items: Array<{ __typename?: 'StorageUnitTrackingMessageDto', storageUnitName: string, pointRegistrationNumber: string, pointId: string, installationId: string, lockStatus: LockStatus, timeStampUtc: string }> } | null };

export const AllocationInspectedFragmentDoc = `
    fragment AllocationInspected on AllocationInspected {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const AllocationTerminatedFragmentDoc = `
    fragment AllocationTerminated on AllocationTerminated {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const ContentDeallocatedFragmentDoc = `
    fragment ContentDeallocated on ContentDeallocated {
  timeStampUtc
  contentEventType
}
    `;
export const ContentPickedUpFragmentDoc = `
    fragment ContentPickedUp on ContentPickedUp {
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentStockedInFragmentDoc = `
    fragment ContentStockedIn on ContentStockedIn {
  courierIdentifier
  timeStampUtc
  contentEventType
}
    `;
export const ContentStockedOutFragmentDoc = `
    fragment ContentStockedOut on ContentStockedOut {
  courierIdentifier
  timeStampUtc
  contentEventType
  email
  firstName
  lastname
}
    `;
export const ContentStoredFragmentDoc = `
    fragment ContentStored on ContentStored {
  pinCode
  phoneNumber
  timeStampUtc
  contentEventType
}
    `;
export const ExportSmsFragmentDoc = `
    fragment ExportSms on ExportSmsDto {
  pointRegistrationNumber
  tenantSubjectName
  projectName
  smsMessagesCount
  messagesCount
}
    `;
export const SubmissionCodeValidatedFragmentDoc = `
    fragment SubmissionCodeValidated on SubmissionCodeValidated {
  isSuccessful
  isScanned
  contentEventType
  timeStampUtc
}
    `;
export const UnknownContentFoundFragmentDoc = `
    fragment UnknownContentFound on UnknownContentFound {
  timeStampUtc
  contentEventType
}
    `;
export const VerifyPickUpAccessCredentialsSuccessFragmentDoc = `
    fragment VerifyPickUpAccessCredentialsSuccess on VerifyPickUpAccessCredentialsSuccess {
  pinCode
  phoneNumber
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const StorageUnitTrackingMessageFragmentDoc = `
    fragment StorageUnitTrackingMessage on StorageUnitTrackingMessageDto {
  storageUnitName
  pointRegistrationNumber
  pointId
  installationId
  lockStatus
  timeStampUtc
}
    `;
export const ContentTrackingDocument = `
    query ContentTracking($trackingIdentifier: String, $tenantSubjectId: UUID, $from: DateTime, $to: DateTime, $pageSize: Int, $pageIndex: Int, $phoneNumber: String) {
  contentTracking(
    trackingIdentifier: $trackingIdentifier
    tenantSubjectId: $tenantSubjectId
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
    phoneNumber: $phoneNumber
  ) {
    __typename
    ... on ContentStockedIn {
      ...ContentStockedIn
    }
    ... on ContentStockedOut {
      ...ContentStockedOut
    }
    ... on ContentStored {
      ...ContentStored
    }
    ... on ContentPickedUp {
      ...ContentPickedUp
    }
    ... on ContentDeallocated {
      ...ContentDeallocated
    }
    ... on UnknownContentFound {
      ...UnknownContentFound
    }
    ... on AllocationInspected {
      ...AllocationInspected
    }
    ... on AllocationTerminated {
      ...AllocationTerminated
    }
    ... on SubmissionCodeValidated {
      ...SubmissionCodeValidated
    }
    ... on VerifyPickUpAccessCredentialsSuccess {
      ...VerifyPickUpAccessCredentialsSuccess
    }
  }
}
    ${ContentStockedInFragmentDoc}
${ContentStockedOutFragmentDoc}
${ContentStoredFragmentDoc}
${ContentPickedUpFragmentDoc}
${ContentDeallocatedFragmentDoc}
${UnknownContentFoundFragmentDoc}
${AllocationInspectedFragmentDoc}
${AllocationTerminatedFragmentDoc}
${SubmissionCodeValidatedFragmentDoc}
${VerifyPickUpAccessCredentialsSuccessFragmentDoc}`;
export const useContentTrackingQuery = <
      TData = ContentTrackingQuery,
      TError = unknown
    >(
      variables?: ContentTrackingQueryVariables,
      options?: UseQueryOptions<ContentTrackingQuery, TError, TData>
    ) =>
    useQuery<ContentTrackingQuery, TError, TData>(
      variables === undefined ? ['ContentTracking'] : ['ContentTracking', variables],
      fetcher<ContentTrackingQuery, ContentTrackingQueryVariables>(ContentTrackingDocument, variables),
      options
    );
export const PointsDocument = `
    query Points {
  points {
    id
    pointSequenceNumber
    pointRegistrationNumber
  }
}
    `;
export const usePointsQuery = <
      TData = PointsQuery,
      TError = unknown
    >(
      variables?: PointsQueryVariables,
      options?: UseQueryOptions<PointsQuery, TError, TData>
    ) =>
    useQuery<PointsQuery, TError, TData>(
      variables === undefined ? ['Points'] : ['Points', variables],
      fetcher<PointsQuery, PointsQueryVariables>(PointsDocument, variables),
      options
    );
export const SentSmsLogsDocument = `
    query SentSmsLogs($smsSentAtFrom: String, $smsSentAtTo: String) {
  sentSmsLogs(smsSentAtFrom: $smsSentAtFrom, smsSentAtTo: $smsSentAtTo) {
    ...ExportSms
  }
}
    ${ExportSmsFragmentDoc}`;
export const useSentSmsLogsQuery = <
      TData = SentSmsLogsQuery,
      TError = unknown
    >(
      variables?: SentSmsLogsQueryVariables,
      options?: UseQueryOptions<SentSmsLogsQuery, TError, TData>
    ) =>
    useQuery<SentSmsLogsQuery, TError, TData>(
      variables === undefined ? ['SentSmsLogs'] : ['SentSmsLogs', variables],
      fetcher<SentSmsLogsQuery, SentSmsLogsQueryVariables>(SentSmsLogsDocument, variables),
      options
    );
export const StorageUnitTrackingDocument = `
    query StorageUnitTracking($pointId: UUID, $storageUnitName: String, $from: DateTime, $to: DateTime, $pageSize: Int!, $pageIndex: Int!) {
  storageUnitTracking(
    pointId: $pointId
    storageUnitName: $storageUnitName
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    items {
      ...StorageUnitTrackingMessage
    }
    pageIndex
    pageSize
    totalPages
    totalCount
    hasPreviousPage
    hasNextPage
  }
}
    ${StorageUnitTrackingMessageFragmentDoc}`;
export const useStorageUnitTrackingQuery = <
      TData = StorageUnitTrackingQuery,
      TError = unknown
    >(
      variables: StorageUnitTrackingQueryVariables,
      options?: UseQueryOptions<StorageUnitTrackingQuery, TError, TData>
    ) =>
    useQuery<StorageUnitTrackingQuery, TError, TData>(
      ['StorageUnitTracking', variables],
      fetcher<StorageUnitTrackingQuery, StorageUnitTrackingQueryVariables>(StorageUnitTrackingDocument, variables),
      options
    );